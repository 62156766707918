import React, { useState } from "react"
import { Link } from "gatsby"

import "./navigation.scss"

export default function Navigation() {
  const [menuActive, setMenuActive] = useState(
    /* this sets the default value */ false
  )
  const toggleMenu = () => setMenuActive(!menuActive)

  return (
    <nav className="site-header__nav">
      <button
        id="at-navtoggle"
        className={
          menuActive ? "nav-header__mtoggle active" : "nav-header__mtoggle"
        }
        onClick={toggleMenu}
      >
        <span>Menu</span>
      </button>
      <ul id="menu-main" className="nav-header__menuwrapper">
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/" activeClassName="active">
            Accueil
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/coaching" activeClassName="active">
            Coaching
          </Link>
        </li>
        <li
          id="menu-item-604"
          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-604"
        >
          <Link to="#" activeClassName="active">
            Mes accompagnements
          </Link>
          <ul className="sub-menu">
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-884">
              <Link to="/developpement-personnel" activeClassName="active">
                Développement personnel
              </Link>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-881">
              <Link to="/bilan-de-competences" activeClassName="active">
                Bilan de compétences
              </Link>
            </li>
            <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-885">
              <Link to="/entretien-de-recrutement" activeClassName="active">
                Entretien de recrutement
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/temoignages" activeClassName="active">
            Témoignages
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/appel-introspection" activeClassName="active">
            Appel d’introspection
          </Link>
        </li>
        {/*                 <li id="menu-item-646" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-646"><a href="https://demo.launchandsell.com/tribe/services/">Workwith me</a></li>
                <li id="menu-item-605" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-605"><a href="https://demo.launchandsell.com/tribe/praise/">Praise</a></li>
                <li id="menu-item-820" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-820"><a href="https://demo.launchandsell.com/tribe/about/">About</a></li>
                <li id="menu-item-993" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-993"><a
                    href="#">Shop</a>
                    <ul className="sub-menu">
                        <li id="menu-item-990" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-990"><a
                            href="https://demo.launchandsell.com/tribe/shop/">Shop</a></li>
                        <li id="menu-item-978" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-978"><a
                            href="https://demo.athenathemes.com/tribe/product/my-awesome-ebook/?productstyle=1">Product Style 1</a></li>
                        <li id="menu-item-997" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-997"><a
                            href="https://demo.launchandsell.com/tribe/product/my-online-course/">Product Style 2</a></li>
                        <li id="menu-item-987" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-987"><a
                            href="https://demo.launchandsell.com/tribe/my-account/">My Account</a></li>
                        <li id="menu-item-989" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-989"><a
                            href="https://demo.launchandsell.com/tribe/cart/">Cart</a></li>
                    </ul>
                </li>  */}
        <li className="menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
        {/* <li id="menu-item-844" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-844"><a
                    href="#">LeadPages</a>
                    <ul className="sub-menu">
                        <li id="menu-item-853" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-853"><a
                            href="https://demo.launchandsell.com/tribe/lead-page-1/">Lead Page 1</a></li>
                        <li id="menu-item-852" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-852"><a
                            href="https://demo.launchandsell.com/tribe/lead-page-2/">Lead Page 2</a></li>
                        <li id="menu-item-859" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-859"><a
                            href="https://demo.launchandsell.com/tribe/lead-page-3/">Lead Page 3</a></li>
                        <li id="menu-item-846" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-846"><a
                            href="https://demo.launchandsell.com/tribe/lead-page-4/">Lead Page 4</a></li>
                    </ul>
                </li> */}
      </ul>
    </nav>
  )
}
