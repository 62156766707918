import React from "react"
import { Link } from "gatsby"

import "./footer.scss"

export default function Foooter() {
  return (
    <footer className="site-footer">
      {/* <div className="at-top-footer">
                <div className="at-container">
                    <aside className="site-footer-widgets" role="complementary">
                        <ul className="at-footer-widget-list">
                            <li className="footer_areas">
                                <div className="widget-area footer_widget">
                                </div>
                            </li>
                            <li className="footer_areas">
                                <div className="widget-area footer_widget">
                                </div>
                            </li>
                            <li className="footer_areas">
                                <div className="widget-area footer_widget">
                                </div>
                            </li>

                            <li className="footer_areas">
                                <div className="widget-area footer_widget">
                                </div>
                            </li>
                        </ul>
                    </aside>
                </div>
            </div> */}
      <div class="wave"></div>
      <div className="at-bottom-footer">
        <div className="at-container">
          <nav className="nav-footer" role="navigation">
            <ul>
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li>
                <Link to="/coaching">Coaching</Link>
              </li>
              <li>
                <Link to="/temoignages">Témoignages</Link>
              </li>
              <li>
                <Link to="/appel-introspection">Appel d’introspection</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="site-footer__copyright" role="contentinfo">
            <p>
              <Link to="/conditions-generales-de-vente">
                Conditions générales de vente
              </Link>
            </p>
            <p>
              © Copyright 2021 • Diane Le Blanc Coaching All Rights Reserved •
              Montréal • Made with ❤ by Mathieu Picciolli
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
