import React from "react"
import { Link } from "gatsby"

import "./header.scss"

export default function Header() {
  return (
    <header className="site-header">
      <div className="at-container">
        <div className="site-header__logowrap">
          <div className="at-container">
            <Link to="/">
              <img
                width="300"
                height="100"
                src={"/images/logo.svg"}
                alt="Logo Diane Le Blanc - Coach d'une vie"
              />
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}
