import React from "react"

import "./social-footer.scss"

export default function SocialFoooter() {
  return (
    <div className="at-socialfooter">
      <div className="at-container">
        <h3 className="at-socialfooter__title">RÉSEAUX SOCIAUX</h3>
        <ul>
          <li className="at-socialfooter_facebook">
            <a
              target="_blank"
              href="https://www.facebook.com/dianeleblanccoaching"
              rel="noreferrer"
            >
              <img src={"/images/facebook.svg"} alt="Logo Facebook" />
              <span>Facebook</span>
            </a>
          </li>
          <li className="at-socialfooter_instagram">
            <a
              target="_blank"
              href="https://www.instagram.com/diane.leblanc.coaching/"
              rel="noreferrer"
            >
              <img src={"/images/instagram.svg"} alt="Logo Instagram" />
              <span>Instagram</span>
            </a>
          </li>
          <li className="at-socialfooter_linkedin">
            <a
              target="_blank"
              href="https://www.linkedin.com/in/diane-le-blanc/"
              rel="noreferrer"
            >
              <img src={"/images/linkedin.svg"} alt="Logo Linkedin" />
              <span>Linkedin</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
