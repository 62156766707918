import React from "react"

import "./layout.scss"

export default function Layout({ children }) {
  return (
    <div className="at-container">
      <div className="at-pagewrapper at-pagewrapper--sboff">
        <main className="at-page" role="main">
          {children}
        </main>
      </div>
    </div>
  )
}
